<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 게시년도 -->
          <c-datepicker
            name="period"
            type="year"
            :range="true"
            label="LBL0010000"
            defaultStart="-3y"
            defaultEnd="0y"
            v-model="period"
          ></c-datepicker>
        </div>
      </template>
    </c-search-box>
    <!-- 안전보건 경영방침 목록 -->
    <c-table
      ref="table"
      title="LBL0010001"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="policyId"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--등록-->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="bookPopup" />
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'saf-hea-manage-policy',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'policyYear',
            field: 'policyYear',
            // 게시년도
            label: 'LBL0010000',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'policyTitle',
            field: 'policyTitle',
            //제목
            label: 'LBLTITLE',
            align: 'left',
            type: "link",
            sortable: true,
          },
          {
            name: 'policyDate',
            field: 'policyDate',
            // 게시일자
            label: 'LBL0010002',
            align: 'center',
            style: 'width:280px',
            sortable: true,
          },
          {
            name: 'regName',
            field: 'regName',
            //작성자/작성일
            label: 'LBLREGUSERNDATE',
            align: 'center',
            style: 'width:300px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: '',
        startYear: '',
        endYear: '',
        startYmd: '',
        endYmd: '',
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'SHM_POLICY',
        taskKey: '',
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sai.shm.policy.list.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYear = this.period[0];
        this.searchParam.endYear = this.period[1];
      } else {
        this.searchParam.startYear = '';
        this.searchParam.endYear = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.bookPopup(row);
    },
    bookPopup(result) {
      this.popupOptions.target = () => import(`${"./safHeaManagePolicyDetail.vue"}`);
      this.popupOptions.title = 'LBL0010003'; // 안전보건 경영방침 상세
      this.popupOptions.param = {
        policyId: result ? result.policyId : '',
      };
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
